import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTerminal, faRobot } from '@fortawesome/free-solid-svg-icons';
import { faGithub, faJava, faTwitter } from '@fortawesome/free-brands-svg-icons';
import vscodeIcon from '../../assets/vsc-sin-fondo.png';
import deepseekIcon from '../../assets/deepseek.png';
import grokIcon from '../../assets/grok.png';
import chatgptIcon from '../../assets/chatgpt.png'; // Importa la imagen de ChatGPT
import styles from './HerramientasStyle.module.css';
import { useTheme } from '../../common/ThemeContext';

export default function Herramientas() {
  const { theme } = useTheme();

  const tools = [
    {
      name: 'GitHub',
      description: 'Control de versiones y colaboración en equipo.',
      icon: <FontAwesomeIcon icon={faGithub} className={styles.icon} />,
      className: styles.github,
    },
    {
      name: 'Git Bash',
      description: 'Una terminal para manejar repositorios Git.',
      icon: <FontAwesomeIcon icon={faTerminal} className={styles.icon} />,
      className: styles.gitBash,
    },
    {
      name: 'Visual Studio',
      description: 'Editor de código ligero y extensible.',
      icon: <img src={vscodeIcon} alt="Visual Studio Code" className={styles.vscodeIcon} />,
      className: styles.vscode,
    },
    {
      name: 'NetBeans',
      description: 'IDE robusto para el desarrollo en Java.',
      icon: <FontAwesomeIcon icon={faJava} className={styles.icon} />,
      className: styles.netbeans,
    },
  ];

  const aiTools = [
    {
      name: 'ChatGPT',
      description: 'Asistente de IA para resolución de problemas.',
      icon: <img src={chatgptIcon} alt="ChatGPT" className={styles.aiIcon} />, // Icono personalizado
      className: styles.chatgpt,
    },
    {
      name: 'Grok',
      description: 'IA de xAI con acceso a datos en tiempo real.',
      icon: <img src={grokIcon} alt="Grok AI" className={styles.aiIcon} />,
      className: styles.grok,
    },
    {
      name: 'DeepSeek',
      description: 'IA avanzada para asistencia en desarrollo e investigación.',
      icon: <img src={deepseekIcon} alt="DeepSeek AI" className={styles.aiIcon} />,
      className: styles.deepseek,
    },
  ];

  return (
    <section className={`${styles.herramientasSection} ${theme === 'dark' ? styles.dark : ''}`}>
      <div className={styles.content}>
        {/* Sección de herramientas de desarrollo */}
        <div className={styles.titleContainer}>
          <h2 className={`${styles.title} ${theme === 'dark' ? styles.titleDark : ''}`}>HERRAMIENTAS DE DESARROLLO</h2>
          <hr className={`${styles.divider} ${theme === 'dark' ? styles.dividerDark : ''}`} />
          <p className={`${styles.description} ${theme === 'dark' ? styles.descriptionDark : ''}`}>
            Estas son las principales herramientas que utilizo en mi flujo de trabajo diario para el desarrollo de software.
          </p>
        </div>
        <div className={styles.toolsContainer}>
          {tools.map((tool) => (
            <div key={tool.name} className={`${styles.toolCard} ${tool.className} ${theme === 'dark' ? styles.toolCardDark : ''}`}>
              {tool.icon}
              <div className={styles.cardBody}>
                <h3 className={`${styles.toolTitle} ${theme === 'dark' ? styles.toolTitleDark : ''}`}>{tool.name}</h3>
                <p className={`${styles.toolDescription} ${theme === 'dark' ? styles.toolDescriptionDark : ''}`}>{tool.description}</p>
              </div>
            </div>
          ))}
        </div>

        {/* Sección de herramientas de IA */}
        <div className={styles.titleContainer}>
          <h2 className={`${styles.title} ${theme === 'dark' ? styles.titleDark : ''}`}>ASISTENTES DE IA</h2>
          <hr className={`${styles.divider} ${theme === 'dark' ? styles.dividerDark : ''}`} />
          <p className={`${styles.description} ${theme === 'dark' ? styles.descriptionDark : ''}`}>
            Herramientas de inteligencia artificial que complementan mi proceso de desarrollo.
          </p>
        </div>
        <div className={styles.toolsContainer}>
          {aiTools.map((tool) => (
            <div key={tool.name} className={`${styles.toolCard} ${tool.className} ${theme === 'dark' ? styles.toolCardDark : ''}`}>
              {tool.icon}
              <div className={styles.cardBody}>
                <h3 className={`${styles.toolTitle} ${theme === 'dark' ? styles.toolTitleDark : ''}`}>{tool.name}</h3>
                <p className={`${styles.toolDescription} ${theme === 'dark' ? styles.toolDescriptionDark : ''}`}>{tool.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}