import React from 'react';
import styles from './FooterStyle.module.css';

export default function Footer() {
  const currentYear = new Date().getFullYear();

  return (
    <footer className={styles.footer}>
      <div className={styles.footerContent}>
        <p className={styles.copyright}>
          © {currentYear} David Milanés. Todos los derechos reservados.
        </p>
      </div>
    </footer>
  );
}
